.hexcelImg {
  width: 15vw;
  padding: 1vw;
  max-width: 150px;
  border-radius: 30px;
}

@media (max-width: 550px) {
  .hexcelImg {
    width: 30vw;
    max-width: 150px;
  }
}
