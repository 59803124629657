.awsImg {
  width: 15vw;
  padding: 1vw;
  max-width: 150px;
  border-radius: 90px;
}

@media (max-width: 550px) {
  .awsImg {
    width: 30vw;
    max-width: 150px;
  }
}
