.aboutmeTop {
  flex-direction: column;
  padding-top: 5vh;
}

.aboutMe {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  width: fit-content;
  align-items: center;
  justify-content: center;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5vw;
  margin-left: 1rem;
  margin-right: 3rem;
}
.awsLogo {
  height: 10vh;
}

.text {
  margin-right: 5vw;
}
.githubContainer {
  display: flex;
  margin-top: 10vh;
  align-items: center;
  justify-content: center;
}

.myFace {
  display: block;

  width: auto;

  margin: auto;
  width: 18vh;
  align-items: center;
  justify-content: center;
  border-radius: 10%;
  border: 1px solid var(--light-text-color);
  margin: 2rem;
  background-color: rgb(4, 18, 5);
}

.textContainer {
}

.expandable {
}

@media (max-width: 550px) {
  .aboutMe {
    flex-direction: column;
  }
  .titleContainer {
    justify-content: space-around;
  }
  .textContainer {
    margin-left: 1rem;
  }
  .myFace {
    width: 15vh;
    margin-bottom: 0;
    margin-top: 0vh;
  }
  .githubContainer {
    margin-top: 2vh;
  }
}

@media (min-width: 1180px) {
  .myFace {
    margin-left: 15vw;
  }
  .text {
    margin-right: 20vw;
  }
}
