.text {
  margin-left: 11vw;
  margin-right: 5vw;
}
.Projects {
  padding-top: 4vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.youtubeVideo {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  height: 60vh;
  width: 60vw;
  /* width: max-content; */
  /* margin-left: 20vw; */
  /* margin-right: 20vw; */
}
.titleContainer {
  display: flex;
  flex-direction: row;
  justify-self: flex-start;
  align-self: flex-start;
  gap: 5vw;
  margin-left: 11vw;
  margin-right: 5vw;
}

@media (max-width: 840px) {
  .youtubeVideo {
    margin-left: 0;
    margin-right: 0;
    width: 90vw;
  }
}
