.timelineContainer {
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.textContainer {
  margin-right: 5vw;
}

.imageContainer {
}

.dot {
  padding: 5px;
  background-color: #bbb;
  border-radius: 50%;
  margin: 2vw;
}

.time {
  flex-grow: 1;
  align-items: center;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: small;
  color: var(--light-text-color);
  font-family: 'Work Sans';
  border: 1px solid var(--light-text-color);
  border-radius: 12px;
  padding: 3px;
  margin-left: 2vw;
  margin-right: 2vw;
  width: 10vw;
}

.timeImgContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (max-width: 550px) {
  .timeImgContainer {
    flex-direction: column;
  }
  .textContainer {
    margin-right: 2vw;
  }
  .time {
    width: 20vw;
  }
  .dot {
    padding: 0px;
  }
}
