.header {
  background-image: url('./images/enchantments.jpg');

  width: 100vw;
  height: auto;
  display: inline-block;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.flexboxContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.tabs {
  display: flex;
  justify-content: flex-start;
  height: 3em;
}

.activeTab {
  text-decoration: none;
  cursor: pointer;
  background: var(--active-tab-color);

  /* text settings */
  box-shadow: inset 0px 1px 1px var(--stroke-color);
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;

  font-family: 'Permanent Marker';

  text-align: center;
  color: var(--light-text-color);

  text-shadow: -1px -1px 0 var(--stroke-color), 1px -1px 0 var(--stroke-color),
    -1px 1px 0 var(--stroke-color), 1px 1px 0 var(--stroke-color);
}

.tab {
  text-decoration: none;
  cursor: pointer;
  background: var(--primary-color);

  /* text settings */

  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;

  font-family: 'Permanent Marker';

  text-align: center;
  color: var(--black-text-color);
  box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.nate {
  font-family: 'Permanent Marker';
  font-style: normal;
  font-weight: 400;
  color: var(--light-text-color);
  margin: 0;
  margin-left: 2vw;
  margin-top: 8vh;

  text-shadow: -1px -1px 0 var(--stroke-color), 1px -1px 0 var(--stroke-color),
    -1px 1px 0 var(--stroke-color), 1px 1px 0 var(--stroke-color);
}

.title {
  font-family: 'Permanent Marker';
  font-style: normal;
  font-weight: 400;
  color: var(--light-text-color);
  margin: 0;
  margin-left: 2vw;

  text-shadow: -1px -1px 0 var(--stroke-color), 1px -1px 0 var(--stroke-color),
    -1px 1px 0 var(--stroke-color), 1px 1px 0 var(--stroke-color);
}

.email {
  font-family: Work Sans, sans-serif;
  text-decoration: none;
  font-style: normal;
  font-size: x-small;
  font-weight: 100;
  color: var(--light-text-color);

  margin: 0;
  margin-left: 2vw;
  margin-right: 2vw;
  margin-bottom: 0.5vh;
  padding: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  background: var(--linkedin-color);
  width: fit-content;
  border-radius: 5px;

  text-shadow: -1px -1px 0 var(--stroke-color), 1px -1px 0 var(--stroke-color),
    -1px 1px 0 var(--stroke-color), 1px 1px 0 var(--stroke-color);
}

.linksContainer {
  display: flex;
  align-items: center;
}

.linkedin {
  height: 1.2rem;
}

@media (max-width: 550px) {
  .header {
    width: auto;
  }

  .tabs {
    font-size: small;
  }
}
