.title {
  background: #b1b1b2;
  border-width: 0px 1px 1px 0px;
  border-style: solid;
  border-color: #ffffff;
  border-radius: 12px;
  font-size: larger;

  font-family: 'Permanent Marker';
  font-style: normal;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  text-align: center;
  border: 2px solid #000000;

  width: fit-content;
  margin-bottom: 1vh;

  margin-right: 2vw;
  padding: 25px;
  padding-top: 2px;
  padding-bottom: 2px;

  text-shadow: -1px -1px 0 var(--stroke-color), 1px -1px 0 var(--stroke-color),
    -1px 1px 0 var(--stroke-color), 1px 1px 0 var(--stroke-color);
}
